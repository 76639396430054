/* tokenDistributionReport.css */
.token-report-card {
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
    width: 400px; 
    height: 440px; 
    position: relative;
    display: flex; 
    flex-direction: column; 
  }
  
  .token-report-card .card-title {
    margin-bottom: 10px; 
  }
  
  .token-report-card .card-body {
    flex: 1; 
    display: flex;
    flex-direction: column;
  }