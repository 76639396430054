/* Base styling for the sidebar */
.sidebar {
    position: fixed;
    top: 0; 
    left: 0;
    bottom: 0;
    width: 250px; 
    z-index: 1000;
    transition: all 0.3s;
    padding: 20px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #aab7cf transparent;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background-color: #171717; 
  }
  
  /* Media queries for responsive adjustments */
  @media (max-width: 1199px) {
    .sidebar {
      left: -300px;
    }
  
    .toggle-sidebar .sidebar {
      left: 0;
    }
  }
  
  .sidebar::-webkit-scrollbar {
    width: 8px;
    background-color: #343a40;
  }
  
  .sidebar::-webkit-scrollbar-thumb {
    background-color: #6c757d;
    border-radius: 4px;
  }
  
  /* Styling for when the sidebar is toggled in responsive view */
  @media (min-width: 1200px) {
    #main,
    #footer {
      margin-left: 250px;
    }
  
    .toggle-sidebar #main,
    .toggle-sidebar #footer {
      margin-left: 0;
    }
  
    .toggle-sidebar .sidebar {
      left: -300px;
    }
  }
  
  /* Styling for navigation items */
  .sidebar-nav {
    list-style: none;
    padding-left: 0;
  }
  
  .sidebar-nav li {
    margin-bottom: 10px;
  }
  
  /* Links styling */
  .sidebar-nav .nav-link {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff; /* White text for better contrast */
    background: transparent;
    padding: 10px 15px;
    border-radius: 4px;
    transition: all 0.3s ease;
  }
  
  .sidebar-nav .nav-link i {
    font-size: 20px;
    margin-right: 15px;
  }
  
  /* Hover effects for links */
  .sidebar-nav .nav-link:hover,
  .sidebar-nav .nav-link:focus {
    color: #f8f9fa;
    background: #495057; /* Slightly lighter background on hover */
    text-decoration: none;
  }
  
  /* Footer styling within sidebar */
  .sidebar-footer {
    padding-top: 20px;
    padding-bottom: 20px;
    color: #adb5bd; /* Gray text for the footer */
    text-align: center;
  }
  
  .sidebar-footer a {
    color: #f8f9fa;
    text-decoration: none;
  }
  
  .sidebar-footer a:hover {
    text-decoration: underline;
  }
  
  .icon-style {
    margin-right: 10px;
  }
  
  .sidebar-logo {
    margin-bottom: 30px;
  }
  
 
  .provider-item {
    border-bottom: 1px solid #6c757d; /* Light gray color for the divider line */
    padding-bottom: 10px; 
  }

  .logo-divider {
    width: 100%;
    height: 0.5px;
    background-color: #6c757d; 
    margin: 10px 0; 
  }