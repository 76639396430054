/* UpdatesCard.css */
.recent-activity-card {
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    padding: 20px; /* Padding around the card content */
    margin-top: 0; /* Space above the card */
    margin-bottom: 20px; /* Space below the card */
    width: 400px;
    height: 350px;
    position: relative;
}

.recent-activity-card .card-title {
    margin-bottom: 25px;
}

.activity-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px; /* Added spacing between items */
  }

  .activity-label {
    color: #ffffff;
    flex-shrink: 0;
    flex-grow: 0;
    min-width: 64px;
    margin-top: 0;
    position: relative; 
    margin-bottom: 20px;
  }

  .activity-label::before {
    content: '';
    position: absolute;
    left: -11px; 
    width: 4px;
    top: 0;
    bottom: 0;
    background-color: currentColor; 
  }

  .activity-content {
    color: white;
    padding-left: 10px;
    flex-grow: 1; 
  }

  .activity-badge {
    margin-top: 10px;
    z-index: 1;
    font-size: 11px;
    line-height: 0;
    border-radius: 50%;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 10px; 
    /* border: 3px solid lightgrey */
  }
  

/* Specific colors for badges */
.bi-circle-fill.green {
    color: #28a745; /* Bootstrap green */
}

.bi-circle-fill.red {
    color: #dc3545; /* Bootstrap red */
}

.bi-circle-fill.blue {
    color: #007bff; /* Bootstrap blue */
}
