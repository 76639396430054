/* Main */
#main {
    margin-top: 60px;
    padding: 20px 30px;
    transition: all 0.3s;
    background-color: #212121;
}

@media (max-width: 1199px) {
    #main {
        padding: 20px;
    }
}
