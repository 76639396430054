@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Inconsolata:wght@200..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  scroll-behavior: smooth;
  --primary: blue;
  --second: pink;
  --bgColor: rgb(0, 0, 0);

}

*{
  margin: 0px;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
  color: #444444;
  background: var(--bgColor);
}

a {
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: var(--second);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Nunito', sans-serif;
}