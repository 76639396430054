/* Dashboard */
/* Filter dropdown */
.dashboard{
    color: #212121;
    background-color: #212121;
    background: #212121;
}
.dashboard .filter {
    position: absolute;
    right: 0px;
    top: 15px;
}

.dashboard .filter .icon {
    color: #aab7cf;
    padding-right: 20px;
    padding-bottom: 5px;
    transition: 0.3s;
    font-size: 16px;
}

.dashboard .filter .icon:hover,
.dashboard .filter .icon:focus {
    color: #4154f1;
}

.dashboard .filter .dropdown-header {
    padding: 8px 15px;
}

.dashboard .filter .dropdown-header h6 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #aab7cf;
    margin-bottom: 0;
    padding: 0;
}

.dashboard .filter .dropdown-item {
    padding: 8px 15px;
}

.dashboard .row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px; 
}

.dashboard .col-12 { 
    flex: 0 0 100%; 
    max-width: 100%; 
    padding: 15px; 
}
