.logo {
    line-height: 1;
}

@media (min-width: 1200px) {
    .logo {
        width: 280px;
    }
}

.logo img {
    max-height: 32px;
    margin-right: 6px;
}

.logo span {
    font-size: 26px;
    font-weight: 700;
    color: #ffffff;
    font-family: 'Nunito', sans-serif;
}

.icon-style{
    color: white;

}