.provider-card {
  width: 100%;
  background-color: rgba(105, 13, 197, 0.103); /* Translucent background */
  backdrop-filter: blur(10px); /* Glass effect */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Subtle border for the glass effect */
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all ease 0.3s; /* Smooth transition for hover effects */
}

.provider-card:hover {
  box-shadow: 0px 0px 20px 1px #B866F6; /* Enhanced shadow on hover */
  border: 1px solid rgba(255, 255, 255, 0.5); /* Brighter border on hover */
}

.provider-card-title {
  font-size: 1.2rem;
  color: #FFF; /* Ensure text color is visible on light background */
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.1); /* Semi-transparent dark background for the title */
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.model-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.2); /* Light border for each row */
  color: white; /* White text color for better visibility */
}

.model-row:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Slight highlight on hover */
}

.model-details {
  display: flex;
  flex-direction: column;
}

.model-name {
  font-weight: 500;
  margin-bottom: 5px; /* Space between name and balance */
}

.model-balance {
  font-size: 0.9rem;
  color: lightgray; /* White text for balance */
}

.model-count {
  background-color: #00A97F; /* Blue background for count */
  color: white;
  border-radius: 50%; /* Circular background */
  padding: 0px; /* Uniform padding for circle */
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center; /* Center text inside the circle */
  min-width: 20px; /* Minimum width for the circle */
}

/* Payout Card Styles */
.payout-card {
  background-color: rgba(105, 13, 197, 0.103); /* Translucent background for glass effect */
  backdrop-filter: blur(10px); /* Frosted glass effect */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Light border to enhance glass effect */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin-bottom: 20px; /* Space between cards */
  padding: 20px; /* Padding inside the card */
  color: #FFF; /* White text color for visibility */
  transition: all ease 0.3s;
}

.payout-card:hover {
  box-shadow: 0px 0px 20px 1px #B866F6; /* Enhanced shadow on hover */
  border: 1px solid rgba(255, 255, 255, 0.5); /* Brighter border on hover */
}

.payout-card-title {
  font-size: 1.2rem; /* Larger font size for titles */
  margin-bottom: 10px; /* Space below the title */
}

.payout-amount {
  font-size: 1.5rem; /* Large font size for emphasis on amount */
  font-weight: bold; /* Bold font for amount */
}

/* Claimable Balance Card Styles */
.claimable-card {
  background-color: rgba(105, 13, 197, 0.103);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  color: #FFF;
  transition: all ease 0.3s;
}

.claimable-card:hover {
  box-shadow: 0px 0px 20px 1px #B866F6; /* Enhanced shadow on hover */
  border: 1px solid rgba(255, 255, 255, 0.5); /* Brighter border on hover */
}


.claimable-card-title {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.claimable-amount {
  font-size: 1.5rem;
  font-weight: bold;
  display: block; /* Makes the amount take a full line for visual emphasis */
  margin-bottom: 10px; /* Space before the button */
}

/* Claim Button */
.claim-button {
  --color: #00A97F;
  padding: 0.8em 1.7em;
  background-color: transparent;
  border-radius: .3em;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: .5s;
  font-weight: 400;
  font-size: 17px;
  border: 1px solid;
  font-family: inherit;
  text-transform: uppercase;
  color: var(--color);
  z-index: 1;
 }
 
 .claim-button::before, .claim-button::after {
  content: '';
  display: block;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 50%;
  z-index: -1;
  background-color: var(--color);
  transition: 1s ease;
 }
 
 .claim-button::before {
  top: -1em;
  left: -1em;
 }
 
 .claim-button::after {
  left: calc(100% + 1em);
  top: calc(100% + 1em);
 }
 
 .claim-button:hover::before, .claim-button:hover::after {
  height: 410px;
  width: 410px;
 }
 
 .claim-button:hover {
  color: rgb(10, 25, 30);
 }
 
 .claim-button:active {
  filter: brightness(.8);
 }
 
 /* Charts Card */
 
.charts-card .card-title{
  margin-top: 20px;
}

.tables-card .card-title{
  margin-top: 20px;
}